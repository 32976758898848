
<template>
    <v-btn color="btnExcel" dark @click="handleDownload()">
        <v-icon>mdi-file-excel</v-icon>Excel
    </v-btn>
</template>
    



<script>
export default {
    name: "dlgExportExcel",
    props: {
        tblHeaders:Array,
        tblBody:Array,
        filename:String,
        bookType:String,
    },      
    components:{
    },
    data() {
        return{
        }
    },
    created(){
    },
    methods:{
        handleDownload() {
            //this.downloadLoading = true
            import('@/vendor/Export2Excel').then(excel => {
                //const tHeader = ['Merchant Name', 'Outlet Name', 'Member Id', 'TX Date', 'Code', 'Reference No.', 'Amount', 'Points']
                //const filterVal = ['merchant_name','outlet_name','login_id','transaction_date','eg_code','reference_no','process_amount','points']
                const tHeader = this.tblHeaders.map(value=>value['text'])
                const filterVal = this.tblHeaders.map(value=>value['value'])
                const list = this.tblBody
                const data = this.formatJson(filterVal, list)
                //const data = list

                excel.export_json_to_excel({
                    header: tHeader,
                    data,
                    filename: this.filename,
                    autoWidth: true,
                    bookType: this.bookType
                })
                this.downloadLoading = false
            })
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => {
                // if (j === 'timestamp') {
                // return parseTime(v[j])
                // } else {
                return v[j]
                // }
            }))
        }
    },
}    

</script>